<template>
  <div class="miniBasketParent">
    <!-- card previews -->
    <!-- CARD ICON -->
    <span class="miniBasketChild mx-2 d-flex align-items-center" style="font-size:1rem; color: var(--pr-color)">
      <mdb-icon icon="shopping-basket" style="scale: 1.3" />
    </span>
    <!-- SMALL CARD PREVIEW -->
    <div
      ref="scrollContainer"
      class="d-flex align-items-center miniBasketChild"
    >
      <div
        v-for="(basketItem, i) in currentBasket"
        :key="i"
        class="d-flex"
        style="position:relative; height: 90%;"
      >
        <CardPreviewFromProps
          class="mr-3 my-2"
          style="height: 100px; max-height: 90%;margin-right: 15px !important;transform: translateY(-4px);width: 50px;font-size:10px !important;"
          :hero="basketItem.Hero"
          :colour="basketItem.Colour"
          :sport="basketItem.Sport"
          :number="basketItem.Number"
          :first-initial="basketItem.FirstInitial"
          :second-initial="basketItem.SecondInitial"
          :is-mini-basket="true"
        />
        <div
          style="position:absolute;top:2px;right:9px;border-radius:50%;background:var(--bg-color);border:var(--pr-color) solid 2px;width: 15px;height:15px;cursor:pointer;"
          class="d-flex align-items-center justify-content-center"
          @click="deleteItemFromBasket(basketItem, i)"
        >
          <i class="fa fa-times" style="color:var(--pr-color);margin-top: 2px;font-size:0.55rem;" />
        </div>
      </div>
    </div>
    <mdb-btn
      style="text-align: center;"
      class="btn primary-btn btn-radius btn-sm btn-md text-nowrap miniBasketChild"
      @click="checkout()"
    >
      CHECKOUT
    </mdb-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import basketService from '@/api-services/basket.service'
import CardPreviewFromProps from '@/components/cardPreviews/cardPreviewFromProps.vue'

export default {
  name: 'MiniBasket',
  components: { CardPreviewFromProps },
  props: {
    fontSize: {
      type: String,
      default: '1rem'
    },
    iconFontSize: {
      type: String,
      default: '1rem'
    }
  },
  computed: {
    ...mapGetters(['currentBasket'])
  },
  created () {
    // if basket empty reload
    if (this.currentBasket.length === 0) {
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
    }
  },
  mounted () {
    this.addScrollEvent()
  },
  methods: {
    addScrollEvent () {
      const scrollContainer = this.$refs.scrollContainer
      scrollContainer.addEventListener("wheel", (evt) => {
        evt.preventDefault()
        scrollContainer.scrollLeft += evt.deltaY
      })
    },
    checkout () {
      this.$router.push('/basket/checkout')
    },
    deleteItemFromBasket (basketItem, index) {
      // checks for a basketorderId - means it is stored backend - so will either delete both front and back or just front (if anonymous)
      if (basketItem.BasketOrdersId) {
        basketService.removeFromBasket(basketItem.BasketOrdersId).then((res) => {
          if (res.data === true) {
            this.$store.commit('removeItemFromBasket', index)
          }
        })
      } else {
        this.$store.commit('removeItemFromBasket', index)
      }
      // if basket empty reload
      if (this.currentBasket.length === 0) {
        this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>
.miniBasketParent {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 5px;
  overflow-x: auto;
  align-content: center;
  width:100%;
  height:140px;
  max-height: 92px;
  min-height: 100px;
  background: var(--bg-color);
  border: 2px solid var(--pr-color);
  border-radius: 10px;
}

.miniBasketChild:first-child {
  width: max-content;
}

.miniBasketChild:last-child {
  width: min-content;
}

.miniBasketChild {
  height: 100%;
  overflow-x: auto;
  align-self: center;
}
</style>
