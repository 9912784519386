<template>
  <div style="height: 100%; width: 100%; overflow: auto" class="d-flex flex-column align-items-center my-auto">
    <h1
      class="font-weight-bold text-center mt-auto whiteFlash"
      style="font-size: clamp(1.2rem, 2vw, 3rem) !important;"
      @click="$store.commit('setMobilePreviewShowing', true)"
    >
      <AnimatedWord v-if="hero == 0 && currentBasket.length > 0" :word="title" />
      <span v-else>{{ title }}</span>
    </h1>
    <div class="pb-2 mb-auto">
      <PerspectiveContainer>
        <CardPreviewFromStore
          class="mb-auto"
          font-size="3rem"
          icon-font-size="3rem"
          :width="cardPreviewWidth"
        />
      </PerspectiveContainer>
    </div> 
    <MiniBasket
      v-if="currentBasket.length > 0"
      style="position: sticky; bottom: 0px;z-index: 6; height: fit-content;"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardPreviewFromStore from '@/components/cardPreviews/cardPreviewFromStore.vue'
import MiniBasket from '@/components/basket/miniBasket.vue'
import PerspectiveContainer from '@/components/UI/perspectiveContainer.vue'
import AnimatedWord from "@/components/UI/bouncingWordWrapper.vue"

export default {
  name: 'PlaySectionRightPanel',
  components: {
    MiniBasket,
    CardPreviewFromStore,
    PerspectiveContainer,
    AnimatedWord
  },
  data () {
    return {
      title: 'YOUR GRASSROOTS HERO',
      windowHeight: window.innerHeight
    }
  },
  computed: {
    ...mapGetters(['currentBasket', 'mobileView', 'hero']),
    cardPreviewWidth () {
      if (this.currentBasket.length > 0) {
        const size = this.windowHeight - 520
        return size < 190 ? `${size}` : `${190}`
      } else {
        const size = this.windowHeight - 420
        return size < 210 ? `${size}` : `${210}`
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    addAnother () {
      this.$router.push({ path: '/play', query: { page: 1 } })
    },
    onResize () {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>

